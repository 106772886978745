const clientComments = {
    variable_1: '#1',
    init() {
        this.events();
    },
    events() {
        /**
         * Execute events
         */
        this.executeSlick();
    },    
    executeSlick() {
        jQuery('.single-item').slick(
            {
                dots: true,
                infinite: true
            }
        );
    },
};
jQuery(() => {
    clientComments.init();
});